<template>
  <v-tooltip :bottom="bottom" :top="top" :left="left" :right="right">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" style="width:250px" v-on="on">
        <label>{{ label }}</label>
        <v-switch v-model="switchValue" :color="color" value :disabled="!editable" :class="classProps" />
      </div>
    </template>
    <div>{{ tip }}</div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    classProps: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    tip: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    value: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: false
    },
    top: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    switchValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('update:value', value)
      }
    }
  }
}
</script>
